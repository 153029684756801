import { Language } from "../hook/useLang"

const brand = 'KDslots'
const product = 'Lucky Egg'
const { bronze,diamon, gold, platinum, silver } = {
    bronze: 'bronze',
    silver: 'silver',
    gold: 'gold',
    platinum: 'platinum',
    diamon: 'diamond'
}

/**
*Indonesian language.
*
* @description
* This is all of the text/letter context in indonesian language.
*/
const id: Language = {
    oke: 'Oke',
    fullscreen_message: 'Click Disini untuk mengubah Menjadi FullScreen',
    rotate_message: 'Silahkan Ubah Tampilan Menjadi Landscape Untuk Bermain',
    prize: {
        congrats: 'selamat!',
        you_have_got: 'anda telah mendapatkan :',
        btn_claim: 'klaim hadiah disini!',
        btn_back: 'kembali'

    },
    point_status: {
        your_point_is: ' point anda: '
    },
    mid_panel: {
        purchase: ' TUKAR ',
        error_purchase: {
            already_have_egg: 'silahkan pecahkan telur terlebih dahulu sebelum menukar dengan telur yang baru'
        }
    },
    eggs_name: {
        bronze,
        silver,
        gold,
        platinum,
        diamon,
    },
    left_panel: {
        exit: 'keluar',
        history: 'history',
        info: 'info',
    },
    last_winner: {
        title: 'last winner',
    },
    egg_breaker: {
        empty_egg_massage: ' pilih lucky egg dibawah dan pecahkan disini! ',
        btn_break: ' pecahkan '
    },
    from_claim: {
        title: 'form klaim hadiah',
        full_name: {
            label: 'Nama Lengkap:',
            placeholder: 'Nama Lengkap Anda',
            error_message: {
                "The name must be at least 3 characters.": "Nama minimal 3 huruf.",
                "The name field is required.": "Nama belum di isi.",
                "The name may not be greater than 40 characters.": "Nama maksimal 40 huruf.",
                "The name format is invalid.": "Simbol dan angka tidak di perkenankan untuk Nama."
            }
        },
        full_address: {
            label: 'Alamat:',
            placeholder: 'Alamat lengkap Anda',
            error_message: {
                "The address must be at least 5 characters.": "Alamat minimal 5 karakter.",
                "The address may not be greater than 100 characters.": "Alamat maksimal 100 karakter.",
                "The address field is required.": "Alamat belum di isi.",
            }
        },
        hamlet_neighbourhood_rt: {
            label: 'RT/RW',
            placeholder: 'RT',
            error_message: {
                "The neighbourhood must be at least 2 characters.": "RT paling sedikit 2 huruf.",
                "The neighbourhood may not be greater than 8 characters.": "RT maksimal 8 karakter.",
                "The neighbourhood field is required.": "RT/RW belum di isi.",
            }
        },
        hamlet_neighbourhood_rw: {
            label: 'RT/RW',
            placeholder: 'RW',
            error_message: {
                "The neighbourhood must be at least 2 characters.": "RW paling sedikit 2 huruf.",
                "The neighbourhood may not be greater than 8 characters.": "RW maksimal 8 karakter.",
                "The neighbourhood field is required.": "RT/RW belum di isi.",
            }
        },
        urban_village: {
            label: 'Kelurahan/Desa',
            placeholder: 'Kelurahan Anda',
            error_message: {
                "The village must be at least 3 characters.": "Kelurahan/Desa paling sedikit 3 huruf.",
                "The village may not be greater than 100 characters.": "Kelurahan/Desa maksimal 100 karakter.",
                "The village field is required.": "Kelurahan/Desa belum di isi.",
            }
        },
        sub_distric: {
            label: 'Kota/Kecamatan',
            placeholder: 'Kota/Kecamatan',
            error_message: {
                "The subdistrict must be at least 3 characters.": "Kota/Kecamatan paling sedikit 3 huruf.",
                "The subdistrict may not be greater than 100 characters.": "Kota/Kecamatan maksimal 100 karakter.",
                "The subdistrict field is required.": "Kota/Kecamatan belum di isi.",
            }
        },
        distric_regency: {
            label: 'Kabupaten',
            placeholder: 'Kabupaten Anda',
            error_message: {
            }
        },
        province: {
            label: 'Provinsi',
            placeholder: 'Provinsi Anda',
            error_message: {
                "The province must be at least 3 characters.": "Provinsi paling sedikit 3 huruf.",
                "The province may not be greater than 100 characters.": "Provinsi maksimal 100 karakter.",
                "The province field is required.": "Provinsi belum di isi.",
            }
        },
        zip_code: {
            label: 'Kode Pos',
            placeholder: 'Kode Pos',
            error_message: {
                "The zipcode must be a number.": "Hanya diperkenankan angka untuk Kode Pos",
                "The zipcode field is required.": "Kode Pos tidak boleh kosong."
            }
        },
        phone: {
            label: 'No. Telepon:',
            placeholder: 'No telepon Anda',
            error_message: {
                "The phone must be a number.": "Hanya diperkenankan angka untuk No Telepon",
                "The phone field is required.": "No Telepon tidak boleh kosong."
            }
        },
        notice: 'wajib isi data diri dengan valid untuk proses pengiriman hadiah.',
        btn_send: 'kirim',
        send_success_message: 'data diri anda berhasil terkirim',
        text_garism : '/'
    },
    msgbox: {
        content_template: {
            egg_purchase_confirmation: {
                title: 'tukar point',
                message: 'apakah anda ingin menukar point dengan lucky egg ini?'
            },
            exit_confirmation: {
                title: 'keluar',
                message: 'apakah anda yakin ingin keluar?'
            }
        },
        confirm: 'ya',
        disconfirm: 'tidak'
    },
    info: {
        one: {
            title: `${product} ${brand}`,
            letter_one: `bagaimana cara mendapatkan ${product} di ${brand}?`,
            letter_two: `Dengan mengumpulkan point yang didapatkan dari total turnover permainan Anda, Anda bisa membuka ${product} yang diinginkan. Total turnover akan dihitung perhari, 1000k = 1 point dan berlaku akumulasi.`,
            letter_three: `NB:\n
            -Point akan hangus 30 hari jika tidak digunakan, terhitung sejak point didapatkan.\n
            -${product} akan hangus jika 7 hari tidak dipecahkan.\n`,
        },
        sk: ([
            {
                title: `KDSLOTS X LUCKY EGG SPESIAL KEMERDEKAAN #79`,
                content: [
`KDslots hadirkan kembali promo LUCKY EGG spesial Kemerdekaan #79
yang semakin mewah dan meriah.
Dapatkan hadiah utama HONDA CRF150L
&
YAMAHA NMAX.`,
                ]
            },
            {
                title: 'SYARAT & KETENTUAN:',
                content: [
                    `1. Aktif bermain di KDslots game Slots, E-sport, Live Casino, Poker,
Sportsbook, Fishing Game, Tangkas, Togel, E-sport, IDN Trade dan IDNRNG.`,
                    `2. Setiap jumlah TURNOVER Rp 1.000.000,- yang dikumpulkan
akan mendapatkan 1 Poin, berlaku kelipatan dan diakumulasi per hari.`
                ]
            },
            {
                title: 'Contoh perhitungan poin:',
                content: [
                    `TURNOVER Rp 1.000.000,-  Rp 1.999.999,- dapat 1 Poin
TURNOVER Rp 2.000.000,- Rp 2.999.999,- dapat 2 Poin
TURNOVER Rp 3.000.000,- Rp 3.999.999,- dapat 3 Poin`,
                ]
            },
            {
                title: 'SYARAT & KETENTUAN:',
                content: [
`3. Turnover dan Poin akan dihitung 1x24 jam setiap hari,
selama periode promosi tanggal  1 – 31 Agustus 2024.`,
`4. Setiap Telur mempunyai syarat point
dan hadiah yang berbeda-beda.`,
`5. Hadiah akan muncul secara acak ketika telur dibuka atau dipecahkan,
kemudian wajib klik tombol Klaim untuk mengisi formulir / data - data.`,
`6. Telur akan hangus jika seminggu tidak dipecahkan,
batas waktu terakhir untuk pembelian telur dan
klaim hadiah adalah tanggal 7 Sept 2024.`,

                ]
            },
            {
                title: 'SYARAT & KETENTUAN:',
                content: [
`7. Tidak diperbolehkan safety bet atau memanipulasi Turnover.`,
`8. Jika terindikasi melakukan kecurangan atau memanipulasi bonus ini maka
KDslots berhak membatalkan bonus`,
`9. KDslots berhak untuk mengubah atau mengakhiri promosi sewaktu-waktu
karena alasan apapun, tanpa pemberitahuan lebih lanjut.`,
'10. Keputusan KDslots adalah mutlak dan tidak dapat di ganggu gugat.',
                ]
            },
            {
                title: 'Bagaimana cara mendapatkan Lucky Egg di KDslots?',
                content: [
`Setiap kali melakukan bet pada permainan game di KDslots,
Anda akan memperoleh poin dan poin yang didapatkan bisa ditukarkan
dengan Lucky Egg dengan level yang tersedia.`,
                ]
            },
            {
                title: 'Cara Main:',
                content: [
`Pada Desktop/Smartphone Anda, buka website KDslots

Silakan melakukan login lalu Klik menu BONUS yang ada didalam menu website KDslots.

Klik gambar kotak tentang Lucky Egg yang muncul pada MENU BONUS tersebut dan
game Lucky Egg akan ditampilkan, kemudian tukarkan poin Anda sesuai dengan
jumlah poin yang tersedia.

"Cek hadiah Anda" jika mendapatkan barang silakan klik menu klaim hadiah
dan isi data diri Anda, apabila sudah berhasil atau sukses,
barang akan terkirim otomatis ke alamat Anda.

NB: Mohon data diri diisi dengan benar / valid
untuk kelancaran proses pengiriman barang.`
                ]
            }
        ]),
        two: {
            name_postfix: 'egg',
            desc_prefix: 'anda berkesempatan mendapatkan',
            desc_postfix: 'dan masih banyak lagi.',
            simple_caption: 'berbagai jenis lucky egg bisa Anda dapatkan dengan menukar point yang dikumpulkan, isi lucky egg seperti Chips ratusan hingga jutaan, Handphone Android/Ios, \nTablet mewah, Motor mewah, dan masih banyak lagi.'
        }
    },
    history: {
        trx_id: 'ID transaksi',
        prize: 'hadiah',
        play_date: 'tanggal bermain',
        claim_now: 'klaim sekarang!',
        expired: 'sudah kedaluarsa!'
    },
    error_connetion: 'tidak dapat memuat hasil. tolong periksa koneksi internet anda.',
    no_record: 'hasil masih kosong.',
    game_unavaliable: 'game tidak aktif lagi.',
    error_message: {
        Unauthorized: 'Anda tidak berhak mengikuti game ini.',
        Expired: 'Maaf Anda Sudah Keluar Dari Permainan Ini.',
        "Already Have!": 'Telur sudah dimiliki.',
        "ajax error": "Terjadi kesalahan",
        "ajax error 500": "Terjadi kesalahan \nsaat menghubungakan ke server.",
        "ajax timeout": "Waktu habis \nsaat menghubungakan ke server.",
        "Less Point!": "Point tidak mencukupi untuk membeli telur.",
        "Has Expired!": "Telur sudah kadaluarsa.",
        "ajax error 429": "Terlalu banyak permintaan"
    },
    success_claim_merch: "Data Anda berhasil dikirim.",
    success_claim_chips: "KLAIM SUDAH BERHASIL, SILAKAN CEK DI DOMPET UTAMA PALING LAMBAT 30 MENIT",
}
export default id